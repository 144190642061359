<template>
  <section class="boldgrid-section">
    <div class="boldgrid-section__content">
      <div class="boldgrid-section__image-container" ref="image">
        <ImageZooming
          :src="data.field_bild_boldgrid_section[0].url"
          :alt="data.field_bild_boldgrid_section[0].alt"
          class="boldgrid-section__image"
        />
      </div>

      <div
        class="boldgrid-section__title-container contents-fades-entry-bottom"
      >
        <h2 class="boldgrid-section__title fades-exit-top">
          {{ data.title[0].value }}
        </h2>
      </div>

      <div class="boldgrid-section__body-text" ref="body">
        <div v-html="sanitise(data.body[0].value)"></div>
      </div>

      <div
        class="boldgrid-section__background background"
        ref="background"
      ></div>
    </div>
  </section>
</template>

<script>
import { visualMixin } from "@/mixins/visualMixin.js";
import { httpMixin } from "@/mixins/httpMixin.js";
import ScrollMagic from "scrollmagic";
import ImageZooming from "@/components/ImageZooming";

export default {
  mixins: [visualMixin, httpMixin],

  components: { ImageZooming },

  props: {
    data: Object
  },

  mounted: function() {
    new ScrollMagic.Scene({
      triggerElement: this.$refs.image,
      offset: 100,
      triggerHook: 0.8,
      reverse: false
    })
      .setClassToggle(
        this.$refs.background,
        "boldgrid-section__background--visible"
      )
      .addTo(this.animationController);

    new ScrollMagic.Scene({
      triggerElement: this.$refs.body,
      offset: 0,
      triggerHook: 0.9,
      reverse: false
    })
      .setClassToggle(this.$refs.body, "boldgrid-section__body-text--visible")
      .addTo(this.animationController);
  }
};
</script>

<style scoped>
.boldgrid-section__content {
  display: grid;
  grid-template: auto / 3fr 9fr 8fr;
}

.boldgrid-section__title-container {
  z-index: 20;
  transition: opacity 0.35s;
  grid-row-start: 2;
  grid-column: 1 / -1;
  text-align: left;
}

.boldgrid-section__title {
  margin: 24px 0 12px;
  font-size: 2rem;
}

.boldgrid-section__body-text {
  z-index: 20;
  text-align: left;
  padding-bottom: 72px;
  box-sizing: border-box;
  grid-row-start: 3;
  grid-column: 1 / -1;
  opacity: 0;
  transform: translateX(-50vw);
  transition: all 0.8s ease-in-out;
}

.boldgrid-section__body-text--visible {
  opacity: 1;
  transform: none;
}

.boldgrid-section__image-container {
  width: calc(100% + 32px);
  margin: 0 -16px;
  grid-column: 1 / -1;
  grid-row-start: 1;
  box-sizing: border-box;
  max-height: 66vh;
}

.boldgrid-section__image {
  width: 100%;
  z-index: 10;
  position: relative;
}

.boldgrid-section__background {
  display: none;
  grid-column-start: 2;
  grid-row: 1 / 4;
  transform: scaleY(0);
  transform-origin: bottom center;
  transition: transform 0.8s ease-in-out;
}

.boldgrid-section__background--visible {
  transform: scaleY(1);
}

@media screen and (min-width: 600px) {
  .boldgrid-section__image-container {
    grid-column: 1 / 3;
    padding: 48px 24px 0 0;
    margin: 0;
  }

  .boldgrid-section__title-container {
    text-align: right;
    padding-right: 48px;
    padding-top: 36px;
  }

  .boldgrid-section__title {
    margin: 24px 0 12px;
    font-size: 6.5rem;
  }

  .boldgrid-section__body-text {
    grid-row-start: 3;
    grid-column: 2 / 3;
    padding: 0 48px 72px;
  }

  .boldgrid-section__background {
    display: block;
  }
}
</style>
